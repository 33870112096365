import {
    Controller
} from "stimulus"

/// Select all/none controller for checkboxes
///
/// Usage example:
/* 
<table data-controller="select-all">
    <thead>
        <tr>
            <th>Name</th>
            <th>Username</th>
        </tr>

        <tr>
            <th>
                <label>
                    <input id="selAllTrigger" type="checkbox" data-action="select-all#toggle">
                    Select all
                </label>
            </th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        {% for u in users %}
        <tr>
            <td>
                <label>
                    <!-- 
                    IMPORTANT! class="selectAll" and data-action="select-all#update" 
                    need to be here for this checkbox to be included in select all operations 
                    -->
                    <input type="checkbox" name="{{u.slug}}"
                        {%if u.some_property == true %}checked{%endif%} class="selectAll"
                        data-action="select-all#update" />
                    <a href="/path/to/{{u.slug}}">{{ u.name }}</a>
                </label>
            </td>
            <td>{{ u.username }}</td>
        </tr>
    </tbody>
</table> 
*/

export default class extends Controller {
    /// checkbox to trigger all/none selection
    trigger = {};

    /// array of checkboxes to be updated on trigger action
    checkboxes = [];

    // Runs every time the controller is connected to the DOM.
    // Initialises the list of checkboxes to be managed and the 
    // trigger checkbox
    connect() {
        this.checkboxes = this.element.getElementsByClassName('selectAll');
        this.trigger = document.getElementById('selAllTrigger');
    }

    // Simple all-or-none toggle for the array of managed checkboxes
    // based on the `checked` property of the trigger
    toggle(evt) {
        for (var i = 0; i < this.checkboxes.length; i++) {
            this.checkboxes[i].checked = this.trigger.checked;
        }
    };

    // Update the state of the trigger checkbox when an individual checkbox
    // is checked/unchecked
    update(evt) {
        var checkedCount = 0;
        for (var i = 0; i < this.checkboxes.length; i++) {
            if (this.checkboxes[i].checked) {
                checkedCount++;
            }
        }
        this.trigger.checked = (checkedCount === this.checkboxes.length);
    };
}
