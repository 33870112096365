import { Controller } from "stimulus"
import {Sortable, Swap} from 'sortablejs';

Sortable.mount(new Swap());

export default class extends Controller {

    connect() {
        var el = this.element;
        var sortable = new Sortable(el, {
            animation: 350,
            ghostClass: 'dragging-ghost',
            group: 'prefix-lists',
            swap: true,
            swapClass: 'dragging-ghost',
            filter: '.placeholder',
            // scroll: true,
        });
    }

}
