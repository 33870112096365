import {
    Controller
} from "stimulus"

/// Table sorting controller
/// Ordering based on table cell text content
/// Activated by clicking a header cell
///
/// Usage example:
/*
<table class="account-list" data-controller="table-sorter">
    <!-- 
    IMPORTANT! data-action="click->table-sorter#sort" 
    is required for this to work
    -->
    <thead data-action="click->table-sorter#sort">
        <th>Name</th>
        <th>Username</th>
        <th>Property 3</th>
        <th>Property 4</th>
        <th>Property N</th>
    </thead>
    <tbody>
        {% for u in users %}
        <tr>
            <td><a href="/path/to/{{u.slug}}">{{ u.name }}</a></td>
            <td>{{ u.username }}</td>
            <td>{{ u.property3 }}</td>
            <td>{{ u.property4 }}</td>
            <td>{{ u.propertyN }}</td>
        </tr>
    </tbody>
    <tfoot></tfoot>
</table>
*/

export default class extends Controller {
    // object to hold values used for sorting the table
    sorter = {};

    // Runs every time the controller is connected to the DOM.
    // Initialises required values for sorting table
    connect() {
        this.sorter.table = this.element;
        this.sorter.tbody = this.sorter.table.tBodies[0];
        this.sorter.trs = this.sorter.tbody.getElementsByTagName("tr");
        this.sorter.sortCol = -1;
        this.sorter.prevCol = -1;

        this.sorter.sortCol = 0;
        this.clearIndicators();
        //var noggin = this.sorter.thead.getElementsByTagName("tr");
        this.sorter.table.getElementsByTagName('th')[0].classList.add('down');
        this.sortTable(); 
    };

    // get the lowercase text value of a table cell
    // uses node.textContent so may break expected sort functionality  
    // if the cell contains multiple children with content  
    getCellValue(row) {
        if (row >= this.sorter.trs.length || (row.cells[this.sorter.sortCol].textContent == "")) {
            return "";
        }
        return row.cells[this.sorter.sortCol].firstChild.textContent.toLowerCase();
    };

    // called when a click event is registered on the table thead
    sort(event) {
        this.sorter.sortCol = event.target.cellIndex;
        var clickedCell = event.target;

        if (this.sorter.prevCol === this.sorter.sortCol) {
            if (clickedCell.classList.contains('up')) {
                clickedCell.classList.remove('up');
                clickedCell.classList.add('down');
            } else {
                clickedCell.classList.remove('down');
                clickedCell.classList.add('up');
            }
            this.reverseTable();
        } else {
            this.clearIndicators();
            clickedCell.classList.add('down');
            this.sortTable();
        }

        this.sorter.prevCol = this.sorter.sortCol;

    };

    clearIndicators() {
        var ths = this.sorter.table.getElementsByTagName("th");
        for (var i = 0; i < ths.length; i++) {
            ths[i].classList.remove("up");
            ths[i].classList.remove("down");
        }
    };

    // order the table using shaker sort
    sortTable() {
        var list = this.sorter.trs;
        var b = 0;
        var t = list.length - 1;
        var swap = true;

        while (swap) {
            swap = false;
            for (var i = b; i < t; ++i) {
                if (this.getCellValue(list[i]).localeCompare(this.getCellValue(list[i + 1])) > 0) {
                    this.exchange(i, i + 1);
                    swap = true;
                }
            }
            t--;

            if (!swap) break;

            for (var i = t; i > b; --i) {
                if (this.getCellValue(list[i]).localeCompare(this.getCellValue(list[i - 1])) < 0) {
                    this.exchange(i, i - 1);
                    swap = true;
                }
            }
            b++;

        }
    };

    // reverse the current ordering of the table
    reverseTable() {
        var i,
            tbody = this.sorter.tbody,
            trs = this.sorter.trs;

        for (i = 1; i < trs.length; i++) {
            tbody.insertBefore(trs[i], trs[0]);
        }
    };

    // swap the positions of two rows in the table
    exchange(i, j) {
        var tbody = this.sorter.tbody,
            trs = this.sorter.trs,
            tmpNode;

        if (i === j + 1) {
            tbody.insertBefore(trs[i], trs[j]);
        } else if (j === i + 1) {
            tbody.insertBefore(trs[j], trs[i]);
        } else {
            tmpNode = tbody.replaceChild(trs[i], trs[j]);
            if (!trs[i]) {
                tbody.appendChild(tmpNode);
            } else {
                tbody.insertBefore(tmpNode, trs[i]);
            }
        }
    };
}
