import {
    Controller
} from "stimulus"

export default class extends Controller {

    connect() {
        this.element.oninput = this.updateFilter;
    }

    updateFilter() {
        var searchTerm = document.getElementById("searchbox").value.toLowerCase();
        var table = document.getElementById("filteredtable");
        var tbody = table.getElementsByTagName("tbody")[0];
        var rows = tbody.getElementsByTagName("tr");
        for (var i = 0; i < rows.length; i++) {
            var tr = rows[i].getElementsByTagName("td");
            if (tr) {
                var showRow = false;
                for (var j = 0; j < tr.length; j++) {
                    var txtValue = tr[j].textContent || tr[j].innerText;
                    if (txtValue.toLowerCase().indexOf(searchTerm) > -1) {
                        showRow = true;
                    }
                }
                if (showRow) {
                    rows[i].style.display = "";
                } else {
                    rows[i].style.display = "none";
                }
            }
        }
    }
}
