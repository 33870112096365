import {
    Controller
} from "stimulus"

export default class extends Controller {


    toggle() {
        var fetchInit = {
            method: 'POST',
            headers: {
                //   'Content-Type': 'image/jpeg'
            },
            mode: 'cors',
            cache: 'default'
        };
        // e.preventDefault();

        var el = this.element;
        var cl = this.data.get("checkslug");
        var uid = this.data.get("userslug");
        var cb = document.getElementById(cl);

        // console.log("check " + JSON.stringify(this.element.id));  
        el.classList.add("spinner");
        // now set server-side, then remove class once set
        fetch("/app/checklists/user/" + cl, fetchInit).then(response => {
            if (response.status === 204) {
                // all OK, just turn off the spinner
                el.classList.remove("spinner");
            } else {
                cb.checked = !cb.checked;
                this.element.classList.remove("spinner");
            }
            // console.log(response.status + " -- " + response.statusText)
        });
    }

}
