import {
    Controller
} from "stimulus";

export default class extends Controller {
    // innerTarget references the 'progress-lozenge-inner' element used to show progress
    static targets = ["east", "west"];

    static values = { progress: Number };

    // connect() runs when the controller is connected to the DOM
    connect() {
        // var donut = this.element;
        var percent = Math.round(this.progressValue);
        if (percent > 100) {
            percent = 100;
        } else if (percent < 0) {
            percent = 0;
        }
        var deg = Math.round(360 * (percent / 100));

        if (percent > 50) {
            //this.element.style.clip = 'rect(auto, auto, auto, auto)';
            this.eastTarget.style.transform = 'rotate(180deg)';
        } else {
            //this.element.style.clip = 'rect(0, 1em, 1em, 0.5em)';
            this.eastTarget.style.transform = 'rotate(0deg)';
        }
        // if (donut) {
        this.eastTarget.style.borderWidth = '0.1em';
        this.westTarget.style.borderWidth = '0.1em';
        //     // $(el + ' .shadow').css('border-width', '0.1em');
        // } else {
        // this.eastTarget.style.borderWidth = '0.5em';
        // this.westTarget.style.borderWidth = '0.5em';
        // $(el + ' .shadow').css('border-width', '0.5em');
        // }
        this.westTarget.style.transform = 'rotate(' + deg + 'deg)';



        // set the width of the inner lozenge to the topic score for the user
        // this.itemTarget.style.transform = "rotate(" + this.data.get("progress") + "deg)";
        // console.log("all the datas is :: " + JSON.stringify(this.data));

        // var deg = this.data.get("progress");
        // var deg = 0;
        // if (this.progressValue >= 100) {
        //     deg = 360;
        // } else if (this.progressValue <= 0) {
        //     deg = this.progressValue / 360 * 100;
        // };

        // console.log("data deg should be set for " + this.itemTarget.className + " now: " + this.progressValue);
        // this.itemTarget.style.webkitTransform = 'rotate(' + deg + 'deg)';
        // this.itemTarget.style.mozTransform = 'rotate(' + deg + 'deg)';
        // this.itemTarget.style.msTransform = 'rotate(' + deg + 'deg)';
        // this.itemTarget.style.oTransform = 'rotate(' + deg + 'deg)';
        // this.itemTarget.style.transform = 'rotate(' + deg + 'deg)';
    }

    updateDonut(el, percent, donut) {
        percent = Math.round(percent);
        if (percent > 100) {
            percent = 100;
        } else if (percent < 0) {
            percent = 0;
        }
        var deg = Math.round(360 * (percent / 100));

        if (percent > 50) {
            this.el.style.clip = 'rect(auto, auto, auto, auto)';
            this.eastTarget.style.transform = 'rotate(180deg)';
        } else {
            this.el.style.clip = 'rect(0, 1em, 1em, 0.5em)';
            this.eastTarget.style.transform = 'rotate(0deg)';
        }
        if (donut) {
            this.eastTarget.style.borderWidth = '0.1em';
            this.westTarget.style.borderWidth = '0.1em';
            // $(el + ' .shadow').css('border-width', '0.1em');
        } else {
            this.eastTarget.style.borderWidth = '0.5em';
            this.westTarget.style.borderWidth = '0.5em';
            // $(el + ' .shadow').css('border-width', '0.5em');
        }
        this.westTarget.style.transform = 'rotate(' + deg + 'deg)';
    }
}
