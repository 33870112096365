import {
    Controller
} from "stimulus"

export default class extends Controller {
    scrollbox = {};
    leftButton = {};
    rightButton = {};
    connect() {

        // TODO: need to look at the URL and set
        // the visible element based on that

        this.scrollbox = document.getElementById("scrollbox");
        this.leftButton = document.getElementById("leftButton");
        this.rightButton = document.getElementById("rightButton");

        this.hide();
        var t = document.getElementById('glossary-A');
        t.classList.remove("hidden");
        var b = document.getElementById('glossary-A-btn');
        b.classList.add("active");
    }

    hide() {
        var letters = document.getElementsByTagName("dl");
        var len = letters !== null ? letters.length : 0;
        var i = 0;
        for (i; i < len; i++) {
            letters[i].classList.add("hidden");
        }

        var buttons = this.scrollbox.getElementsByClassName("scrollbox-button");
        var btn_len = buttons !== null ? buttons.length : 0;
        var j = 0;
        for (j; j < btn_len; j++) {
            buttons[j].classList.remove("active");
        }
    }

    switch (e) {
        e.preventDefault();
        var ref = e.target.getAttribute("href");
        var t = document.getElementById(ref.substring(1));
        this.hide();
        t.classList.remove("hidden");

        var b = document.getElementById(ref.substring(1) + "-btn");
        console.log("Trying to set: " + ref.substring(1) + "-btn");
        b.classList.add("active");
    }

    scrollRight(e) {
        this.scrollbox.scrollLeft += 120;
    }

    scrollLeft(e) {
        this.scrollbox.scrollLeft -= 120;
    }
}
