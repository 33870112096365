import {
    Controller
} from "stimulus";

export default class extends Controller {
    // innerTarget references the 'progress-lozenge-inner' element used to show progress
    static targets = ["inner"];

    // connect() runs when the controller is connected to the DOM
    connect() {
        // set the width of the inner lozenge to the topic score for the user
        this.innerTarget.style.width = this.data.get("progress") + "%";
    }
}
