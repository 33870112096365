import {
    Controller
} from "stimulus";

export default class extends Controller {
    go(e) {
        e.preventDefault();
        let main = document.getElementById("main");
        main.focus();
    }
}
