import {
    Controller
} from "stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {

    connect() {
        var el = this.element;
        var sortable = new Sortable(el, {
            animation: 175,
            ghostClass: 'dragging-ghost',
            onUpdate: function (evt) {
                var items = el.getElementsByTagName("li");
                for (var i = 0; i < items.length; ++i) {
                    items[i].getElementsByClassName("grab-handle")[0].textContent = i + 1;
                }
            },
        });
    }

}
